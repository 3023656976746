import React, { useState } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';

import { FormID, I18nText, IsLoading, UUID } from '../../../../../types';
import {
  CreateSmartContractShareFormData,
  CreateSmartContractShareFormView
} from './CreateSmartContractShareForm.types';
import { CheckableUsersListItemUserType } from '../../../../common/modalButtons/InviteUsersModalButton/components/InviteUsersModalBody/components/CheckableUsersListItem';

import { CreateSmartContractShareFormChangeShare } from './components/CreateSmartContractShareFormChangeShare';
import { CreateSmartContractShareFormChangeUsers } from './components/CreateSmartContractShareFormChangeUsers';

import { Form } from '../../../../../helpers/Form';

interface CreateSmartContractShareFormProps {
  invitedUserUuids?: UUID[];
  i18nTitle?: I18nText;
  form: FormID;
  isLoading?: IsLoading;
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  formView: CreateSmartContractShareFormView;
  goToSelectUsersView?: () => void;
  withoutEditUsers?: boolean;
}

function CreateSmartContractShareForm({
  invitedUserUuids,
  control,
  form,
  formView,
  setValue,
  getValues,
  goToSelectUsersView,
  isLoading,
  i18nTitle,
  withoutEditUsers
}: CreateSmartContractShareFormProps) {
  const [selectedUsers, setSelectedUsers] = useState<
    CheckableUsersListItemUserType[]
  >([]);

  return (
    <Form id={form}>
      {formView === CreateSmartContractShareFormView.SELECT_USERS && (
        <div className="flex-1 px-2">
          <div className="px-4">
            <Controller
              control={control}
              name="users"
              render={({ field: { onChange, value } }) => (
                <CreateSmartContractShareFormChangeUsers
                  onChange={onChange}
                  users={value}
                  invitedUserUuids={invitedUserUuids}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
              )}
            />
          </div>
        </div>
      )}

      {formView === CreateSmartContractShareFormView.CHANGE_SHARE && (
        <CreateSmartContractShareFormChangeShare
          control={control}
          setValue={setValue}
          getValues={getValues}
          isLoading={isLoading}
          goToBack={goToSelectUsersView}
          i18nTitle={i18nTitle}
          withoutEditUsers={withoutEditUsers}
        />
      )}
    </Form>
  );
}

export default CreateSmartContractShareForm;
