import React, { Fragment } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useFieldArray
} from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { I18nText, IsLoading } from '../../../../../../../types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { CreateSmartContractShareFormData } from '../../CreateSmartContractShareForm.types';

import { CreateSmartContractShareFormChangeShareHeader } from '../CreateSmartContractShareFormChangeShareHeader';
import { CreateSmartContractShareFormChangeShareUserField } from '../CreateSmartContractShareFormChangeShareUserField';
import { CreateSmartContractShareFormChangeShareIterationField } from './components/CreateSmartContractShareFormChangeShareIterationField';

import { Translate } from '../../../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { SmartContractSharesPermissions } from '../../../../../smartContractSharesConstants';

import { usersKeys } from '../../../../../../../locales/keys';

interface CreateSmartContractShareFormChangeShareProps {
  control: Control<CreateSmartContractShareFormData>;
  setValue: UseFormSetValue<CreateSmartContractShareFormData>;
  getValues: UseFormGetValues<CreateSmartContractShareFormData>;
  isLoading?: IsLoading;
  goToBack?: () => void;
  i18nTitle?: I18nText;
  withoutEditUsers?: boolean;
}

function CreateSmartContractShareFormChangeShare({
  control,
  setValue,
  getValues,
  isLoading,
  goToBack,
  i18nTitle,
  withoutEditUsers
}: CreateSmartContractShareFormChangeShareProps) {
  const currentUser = useCurrentUser();

  const { fields: clients, remove: removeClient } = useFieldArray({
    control,
    name: 'users.clients'
  });

  const { fields: workers, remove: removeWorker } = useFieldArray({
    control,
    name: 'users.workers'
  });

  return (
    <Fragment>
      <CreateSmartContractShareFormChangeShareHeader
        goToBack={goToBack}
        i18nText={i18nTitle}
      />
      <div className="flex-1 px-2">
        <div className="p-4 space-y-4">
          {!isEmpty(clients) && (
            <div>
              <div className="font-light text-xs uppercase mb-2">
                <Translate id={usersKeys.clientsPlural} />
              </div>

              <div className="align-middle inline-block min-w-full relative">
                <div className="overflow-auto">
                  <table className="min-w-full relative z-0">
                    <tbody>
                      {clients.map((client, index) => (
                        <CreateSmartContractShareFormChangeShareUserField
                          key={client.id}
                          userId={client.userId}
                          index={index}
                          isClient
                          onRemove={
                            withoutEditUsers
                              ? undefined
                              : () => removeClient(index)
                          }
                          disabled={isLoading}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {!isEmpty(workers) && (
            <div>
              <div className="font-light text-xs uppercase mb-2">
                <Translate id={usersKeys.workersPlural} />
              </div>

              <div className="align-middle inline-block min-w-full relative">
                <div className="overflow-auto">
                  <table className="min-w-full relative z-0">
                    <tbody>
                      {workers.map((worker, index) => (
                        <CreateSmartContractShareFormChangeShareUserField
                          key={worker.id}
                          userId={worker.userId}
                          index={index}
                          onRemove={
                            withoutEditUsers
                              ? undefined
                              : () => removeWorker(index)
                          }
                          disabled={isLoading}
                          control={control}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          <CheckPermissions
            action={
              SmartContractSharesPermissions.READ_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD
            }
          >
            <CreateSmartContractShareFormChangeShareIterationField
              control={control}
              disabled={
                !currentUser.hasPermissions(
                  SmartContractSharesPermissions.CHANGE_SMART_CONTRACT_SHARE_CREATE_ITERATION_FIELD
                ) || isLoading
              }
            />
          </CheckPermissions>
        </div>
      </div>
    </Fragment>
  );
}

export default CreateSmartContractShareFormChangeShare;
